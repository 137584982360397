import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton, SaveQuestionModal, SelectorList, SurveyDurationList } from "../components";
import { DEVICE_TYPE, FONT, QUESTION_TYPE, THEME } from "../enums";
import { MultipleChoiceQuestion, MultipleChoiceSurveyForm, MySurvey } from "../interfaces";
import { MAIN_APP_ROUTES } from "../routes";
import { convertArrayToObj, getDurationByDate, getFileSize, getQuestionInitObject, getSubscriptionCount, initMultiChoiceOptions } from "../services";
import { FormInput, FormInputWithImage, FormSelector, CustomToggle } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, FILE_SIZE_LIMIT } from "../variables";
import * as fromStore from "../store";
import { SpinnerScreen } from "../utils/spinner.screen";

const wrapperHeight = (currentWindowHeight() / 3) * 2;
const questionBank: MySurvey[] = require("../../assets/others/question-bank.json");

const TOP_FORM = [
    {
        field: 'text',
        label: 'yourQuestion',
        required: true,
        secure: false,
        type: 'none',
        textContentType: 'none',
        placeholder: 'Hi Friends, Can we pick a common color to wear for Tasha’s wedding!',
        multiline: true,
        numberOfLines: 4,
        backgroundColor: THEME.INPUT_BACKGROUND

    },
]

const OPTIONS_FORM = [

    {
        field: '0',
        label: 'option',
        optionNumber: 0,
        required: true,
        secure: false,
        type: 'option',
        textContentType: 'none',
        placeholder: 'Enter Option',
        backgroundColor: THEME.INPUT_BACKGROUND,
        remove: false

    },
    {
        field: '1',
        label: 'option',
        optionNumber: 1,
        required: true,
        secure: false,
        type: 'option',
        textContentType: 'none',
        placeholder: 'Enter Option',
        backgroundColor: THEME.INPUT_BACKGROUND,
        remove: false

    },
]

const SINGLE_OPTION = [

    // {
    //     field: 'category',
    //     label: 'selectACategory',
    //     secure: false,
    //     type: 'selector',
    //     textContentType: 'none',
    //     placeholder: 'Relationship',

    // },
    {
        field: 'nameTheSurvey',
        label: 'nameTheSurvey',
        secure: false,
        type: 'none',
        textContentType: 'none',
        placeholder: 'Shoes',

    },
    {
        field: 'duration',
        label: 'surveyDuration',
        secure: false,
        type: 'selector',
        textContentType: 'none',
        placeholder: '30 minutes',
        pattern: ''

    },

    {
        field: 'resultpermission',
        label: 'Make Results Public',
        secure: false,
        type: 'toggle',
        textContentType: 'none',
        placeholder: '',

    },
];

const CATEGORIES = [
    { name: "Relationship", code: "R" },
    { name: "Cloths", code: "C" },
    { name: "Friends", code: "F" },
    { name: "Others", code: "O" },
]

interface State {
    type: string,
    question: string,
    category?: any,
    nameTheSurvey?: string,
    duration?: any,
    options?: any,
    createdAt?: string,
}
export function MultipleChoiceScreen(props: any) {
    const { history, location, match } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { error, loading, draft } = useSelector((state) => fromStore.selectDraftState(state));
    const categories = useSelector((state) => fromStore.selectCategories(state));
    const categoryState = useSelector((state) => fromStore.selectCategoriesState(state));
    const [isVisible, setIsVisible] = useState<any>({
        category: false,
        duration: false
    });
    console.log(location);
    const [currentOptionNumber, setCurrentOptionNumber] = useState<number>(1);
    const [optionForm, setOptionForm] = useState<any[]>(OPTIONS_FORM);
    const [state, setState] = useState<MultipleChoiceSurveyForm>({
        type: QUESTION_TYPE.MULTIPLE_CHOICE,
        text: '',
        category: null,
        nameTheSurvey: '',
        duration: null,
        options: null,
        resultpermission: false,
    });
    const [upload, setUpload] = useState<boolean>(false);
    const [isInit, setIsInit] = useState<boolean>(true);
    function toggleSwitch(field: string) {
        console.log('pass fields',field);
        
        // console.log(state[field]);
        setState({ ...state, [field]: !state[field] });
        console.log(state);
        // dispatch(fromStore.updateProfileSettings({
        //     id: user?.id as string,
        //     enabledNotifications: field === "enabledNotifications" ? !state[field] : state.enabledNotifications,
        //     newsletterSubscribed: field === "newsletterSubscribed" ? !state[field] : state.newsletterSubscribed
        // }))
    }
    useEffect(() => {
        removeState();
        // console.log(match.params);
        dispatch(fromStore.getCategories());
        if (match.params) getDraftInfo(match.params);
        else setState({
            ...state,
            options: {
                0: {
                    image: null,
                    value: '',
                },
                1: {
                    image: null,
                    value: '',
                }
            }
        });
        return () => {
            removeState();
        }
    }, []);

    useEffect(() => {
        if (draft) initState(draft);
        else {
            setIsInit(false);
        }
        return () => {

        }
    }, [draft]);

    function getDraftInfo(params) {

        if (params && params.id && questionBank.find(doc => doc.id === params.id)) {
            const data = questionBank.find(doc => doc.id === params.id);
            if (data) setOptionForm(buildOptions(data.options));
            if (data) setState({
                type: QUESTION_TYPE.MULTIPLE_CHOICE,
                text: data.text,
                category: data.category,
                nameTheSurvey: data.nameTheSurvey ? data.nameTheSurvey : '',
                duration: getDurationByDate(data.startTime, data.endTime),
                options: convertArrayToObj(data.options),
            });
        }
        else if (params && params.id) {
            dispatch(fromStore.removeDraft());
            dispatch(fromStore.getDraft(params.id));
        }
        else if (location && location.state && location.state.previewQuestion) {
            setState(location.state.previewQuestion);
        }
        else {
            setState({
                ...state,
                options: {
                    0: {
                        image: null,
                        value: '',
                    },
                    1: {
                        image: null,
                        value: '',
                    }
                }
            });
        }
    }
    function removeState() {
        dispatch(fromStore.removeDraft());
    }

    function initState(draft: MySurvey) {
        setOptionForm(buildOptions(draft.options));
        const newState = getQuestionInitObject(draft) as MultipleChoiceSurveyForm;
        setState(newState);
        setIsInit(false);
    }

    if ((!draft && loading) || isInit) {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <SpinnerScreen />
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={styles.container}>
                <div style={styles.header}>
                    <CustomText customStyle={styles.headerTxt}>{t('createMultipleChoiceQuestion')}</CustomText>
                </div>
                <div style={styles.formContent}>
                    {TOP_FORM.map((doc, index) => (

                        <div key={doc.field} style={styles.inputField}>
                            {
                                (doc.type !== 'selector' && doc.type !== 'multiselector' && doc.type !== 'option' && doc.type !== 'toggle') && <FormInput
                                    id={doc.field}
                                    onChange={handleFieldChange}
                                    secure={doc.secure}
                                    placeholder={doc.placeholder}
                                    type={doc.type}
                                    value={state[doc.field]}
                                    // pattern={doc.pattern}
                                    label={t(doc.label)}
                                    // keyboardtype={doc?.keyboardtype}
                                    multiline={doc.multiline}
                                    numberOfLines={doc.numberOfLines}
                                    required={doc.required}
                                    textContentType={doc.textContentType}
                                    backgroundColor={doc.backgroundColor}
                                />
                            }
                        </div>
                    ))}
                    {state?.options && optionForm.map((doc, index) => (
                        <div key={doc.field} style={styles.inputField}>
                            {
                                (doc.type === 'option') && <FormInputWithImage
                                    id={doc.field}
                                    onChange={handleOptionChange}
                                    secure={doc.secure}
                                    placeholder={doc.placeholder}
                                    type={doc.type}
                                    index={index}
                                    value={state.options[doc.field]}
                                    pattern={doc.pattern}
                                    label={t(doc.label, { value: doc.optionNumber + 1 })}
                                    keyboardtype={doc?.keyboardtype}
                                    multiline={doc.multiline}
                                    numberOfLines={doc.numberOfLines}
                                    backgroundColor={doc.backgroundColor}
                                    remove={doc.remove}
                                    required={doc.required}
                                    textContentType={doc.textContentType}
                                    onRemove={onOptionRemove}
                                />
                            }
                        </div>
                    ))}
                    {optionForm.length < 4 &&<div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginTop: 10
                    }}>
                        <div style={styles.btn}>
                            <CustomButton
                                name={t('+addOption')}
                                enable={true}
                                padding={10}
                                backgroundColor={"#C1C1C1"}
                                onClick={onOptionAdd}
                            />
                        </div>

                    </div>}
                    {SINGLE_OPTION.map((doc, index) => (
                        <div key={doc.field} style={styles.inputField}>
                            {doc.type === 'selector' &&
                                <FormSelector
                                    lable={t(doc.label)}
                                    placeholder={doc.placeholder}
                                    selected={state[doc.field]}
                                    translate={doc.field != "category"}
                                    onClick={() => setIsVisible({ [doc.field]: !isVisible[doc.field] })}
                                />
                            }
                            {doc.type === 'toggle' &&
                                <div>
                                    <div style={styles.option}>
                                        <CustomText customStyle={styles.title}>{t(doc.label)}</CustomText>
                                    </div>
                                    <CustomToggle
                                        checked={state[doc.field]}
                                        onClick={() => {
                                            toggleSwitch(doc.field)
                                        }}
                                    />
                                </div>
                            }
                            {
                                (doc.type !== 'selector' && doc.type !== 'multiselector' && doc.type !== 'option' && doc.type !== 'toggle') && <FormInput
                                    id={doc.field}
                                    onChange={handleFieldChange}
                                    secure={doc.secure}
                                    placeholder={doc.placeholder}
                                    type={doc.type}
                                    value={state[doc.field]}
                                    pattern={doc.pattern}
                                    label={t(doc.label)}
                                    // keyboardtype={doc?.keyboardtype}
                                    // multiline={doc.multiline}
                                    // numberOfLines={doc.numberOfLines}
                                    textContentType={doc.textContentType}
                                // backgroundColor={doc.backgroundColor}
                                />
                            }

                        </div>
                    ))}

                </div>
                <div style={styles.actions}>
                    <div style={styles.btn}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('preview')}
                            enable={_isFormValid()}
                            padding={12}
                            onClick={() => {
                                // console.log(state);
                                history.push(MAIN_APP_ROUTES.PREVIEW_QUESTION, {
                                    type: QUESTION_TYPE.MULTIPLE_CHOICE,
                                    question: state,
                                    id: match.params ? match.params.id : undefined
                                })
                            }}
                        />
                    </div>
                    <div style={styles.btn}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('save')}
                            enable={_isFormValid()}
                            padding={12}
                            onClick={() => {
                                // console.log(state);
                                setUpload(!upload)
                            }}
                        />
                    </div>
                    <div style={styles.btn}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('post')}
                            enable={_isFormValid()}
                            padding={12}
                            onClick={() => postMyQuestion()}
                        />
                    </div>
                    <div style={styles.btn}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('cancel')}
                            enable={true}
                            padding={12}
                            backgroundColor={THEME.CANCEL}
                            onClick={() => { history.push(MAIN_APP_ROUTES.CREATE_QUESTION); }}
                        />
                    </div>
                </div>

            </div>
            {isVisible['category'] &&
                <SelectorList
                    list={categories}
                    selected={state['category']}
                    loading={categoryState.loading}
                    type={"surveyCategory"}
                    setItem={(doc) => {
                        setState({ ...state, ['category']: doc });
                        setIsVisible({ ...isVisible, category: false });
                    }}
                    title={t("category")}
                    visible={isVisible['category']}
                    wrapperHeight={wrapperHeight}
                    onClose={() => { setIsVisible({ ...isVisible, category: false }); }}
                    onDissmiss={() => { setIsVisible({ ...isVisible, category: false }); }}
                />
            }
            {isVisible['duration'] && 
                <SurveyDurationList
                    selected={state['duration']}
                    loading={false}
                    setItem={(doc) => {
                        setState({ ...state, ['duration']: doc });
                        setIsVisible({ ...isVisible, duration: false });
                    }}
                    title={t("duration")}
                    visible={isVisible['duration']}
                    wrapperHeight={wrapperHeight}
                    onClose={() => { setIsVisible({ ...isVisible, duration: false }); }}
                    onDissmiss={() => { setIsVisible({ ...isVisible, duration: false }); }}
                />
            }
            {upload && <SaveQuestionModal
                uploading={upload}
                doc={state}
                onClick={() => { }}
                onClose={() => { setUpload(!upload) }}
                onSuccess={onSucces}
            />}
        </div>
    );

    function handleOptionChange(fieldId, value) {
        setState({ ...state, ['options']: { ...state.options, [fieldId]: value } });
    }


    function handleFieldChange(fieldId, value) {
        setState({ ...state, [fieldId]: value });
    }

    function _isFormValid() {
        const { text, category, nameTheSurvey, duration } = state;
        const options = state.options ? Object.keys(state.options).map((key) => state.options[key]) : [];
        if (text == '') return false;
        // if (!category) return false;
        // if (nameTheSurvey == '') return false;
        if (!duration) return false;
        if (!validateOptions(options)) return false;
        return true;
    }

    function validateOptions(options: any[]): boolean {
        let result: boolean = true;
        options.forEach(option => {
            if (option.value === '') result = false;
            else if(option.image && option.image.size && (getFileSize(option.image.size) > FILE_SIZE_LIMIT)) {
                result = false;
            }
        })
        return result;
    }

    // function getSurvayDurationInfo(value: any) {
    //     if (!value) return '';
    //     const name = getSubscriptionCount(value);
    //     if (name) {
    //         return {
    //             name,
    //         }
    //     }
    //     return '';

    // }

    function buildOptions(options: any[]) {
        setCurrentOptionNumber(options.length - 1);
        return options.map((option, index) => {
            return {
                field: `${index}`,
                label: 'option',
                optionNumber: index,
                required: true,
                secure: false,
                type: 'option',
                textContentType: 'none',
                placeholder: 'Enter Option',
                backgroundColor: THEME.INPUT_BACKGROUND,
                remove: (index === 0 || index === 1) ? false : true,
            }
        })
    }

    function onOptionRemove(index: number) {
        optionForm.splice(index, 1);
        let newForm = [...optionForm];
        setOptionForm(newForm);
        delete state.options[currentOptionNumber];
        let newOption = state.options;
        setState({ ...state, ['options']: newOption });
        setCurrentOptionNumber(currentOptionNumber - 1);
    }

    function onOptionAdd() {
        if(optionForm.length >= 4) return;
        let doc = {
            field: `${currentOptionNumber + 1}`,
            label: 'option',
            optionNumber: currentOptionNumber + 1,
            required: true,
            secure: false,
            type: 'option',
            textContentType: 'none',
            placeholder: 'Enter Option',
            backgroundColor: THEME.INPUT_BACKGROUND,
            remove: true

        };
        setCurrentOptionNumber(currentOptionNumber + 1);
        setOptionForm([...optionForm, doc]);
        setState({ ...state, ['options']: { ...state.options, [currentOptionNumber + 1]: { value: '', image: null } } });
    }



    // function saveQuestion() {
    //     dispatch(fromStore.addDraft({
    //         id: match.params && match.params.data ? match.params.data.id : new Date().getTime().toString(),
    //         type: QUESTION_TYPE.MULTIPLE_CHOICE,
    //         question: { ...state, createdAt: new Date().toISOString() }
    //     }));
    //     dispatch(fromStore.saveDrafts());
    //     history.push(MAIN_APP_ROUTES.DRAFTS);
    // }

    function onSucces(response) {
        if (state.id) dispatch(fromStore.updateDraftSuccess(response));
        else dispatch(fromStore.saveDraftSuccess(response));
        setUpload(!upload);
        history.push(MAIN_APP_ROUTES.DRAFTS);
    }

    function postMyQuestion() {
        // if (!state.image) return;
        // uploadQuestionImage(state.image);
        // console.log(SurveysService.getQuestionSendFormat(state));
        history.push(MAIN_APP_ROUTES.POST_QUESTION, { id: (match.params && match.params.id) ? match.params.id : null, question: state })
    }
}


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        height: '100%',
        overflowY: 'auto'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerTxt: {
        fontSize: 15,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica

    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputField: {
        marginBottom: 5
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: 20
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        // height: 40,
        marginBottom: 15
    },
    selectorMenu: {
        position: 'absolute',
        backgroundColor: THEME.WHITE,
        width: '100%',
        height: '100%',
        paddingTop: 20
    }
})