import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FONT, THEME } from "../enums";
import CustomText from "./custom-text";
import { IconProvider, ImageWithSpinner } from "./icon-provider";
import { AiFillInfoCircle, AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'

interface ImageInputProps {
    label?: string,
    placeholder?: string,
    fieldStyles?: any,
    image: any | any[],
    uri?: string | null,
    onClick: (doc: any) => void,
    onRemove: () => void,
    onInfo?: () => void,
}
const boxWidth = 70;

export function ImageInput(props: ImageInputProps) {
    const { label, placeholder, fieldStyles, image, uri, onClick, onRemove, onInfo } = props;
    const inputFile = useRef<HTMLInputElement>(null)

    return (
        <div style={{ ...styles.container, ...fieldStyles }}>
            <input ref={inputFile} id={'file-input'} type={'file'} accept={"image/*"} style={{ display: 'none' }} onChange={onChange} />
            {label ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CustomText customStyle={styles.lableHeader}>{label}</CustomText>
                <AiFillInfoCircle size={20} color={"gray"} onClick={onInfo} style={{cursor: 'pointer'}} />
            </div> : null}
            <div style={styles.imgInputContainer}>
                <ImagePreview
                    image={image}
                    uri={uri}
                    placeholder={placeholder}
                    onAddMore={onAddMore}
                    onRemove={onRemoveImage}
                />
            </div>
        </div>
    );

    function onRemoveImage() {
        const element = document.getElementById("file-input") as HTMLInputElement;
        if(element) {
            element.value = '';
            element.files = null;
        }
        onRemove();
    }

    function onChange(e) {
        if (e.target.files[0]) onClick(e.target.files[0]);
    }

    function onAddMore() {
        if (inputFile.current) inputFile?.current.click();
    }
}

interface ImagePreviewProps {
    image: any,
    uri?: string | null,
    placeholder?: string,
    onRemove: () => void,
    onAddMore: () => void
}

function ImagePreview(props: ImagePreviewProps) {
    const { image, uri, placeholder, onRemove, onAddMore } = props;
    const { t, i18n } = useTranslation();
    const [dataUrl, setDataUrl] = useState<any>();

    useEffect(() => {
        readImageFile();
    }, [image])

    function readImageFile() {
        if (image === null) {
            setDataUrl(null);
            return;
        }
        let file = image;
        let reader = new FileReader();
        reader.onload = (event) => {
            // The file's text will be printed here
            if (event.target) {
                setDataUrl(event.target.result);
                // console.log(event.target.result)
            }

        };

        reader.readAsDataURL(file);
    }

    if (uri && image === null) {
        return (
            <ImageThumbnail uri={uri} index={0} onRemove={onRemove} />
        )
    }

    if (dataUrl) {
        return (
            <ImageThumbnail uri={dataUrl} index={0} onRemove={onRemove} />
        )
    }
    return (
        <div style={styles.imgInstructions}>
            <div style={{ width: 32, height: 40 }}>
                <IconProvider type={"file"} />
            </div>
            <CustomText customStyle={styles.imgInputPlaceholder}>{placeholder}</CustomText>
            <div style={{ display: 'flex', flexDirection: 'row', }}>
                <CustomText customStyle={{ marginRight: 5 }}>{'or'}</CustomText>
                <div
                    style={{}}
                    onClick={onAddMore}
                >

                    <CustomText
                        className={"active-text"}
                        customStyle={{
                            ...styles.brwTxt,
                            // ...{ color: pressed ? 'rgba(230,230,230,0.2)' : '#359BE0' }
                        }}
                    >{t("browseYourGallery")}</CustomText>

                </div>
            </div>
        </div>
    )
}

interface ImageThumbnailProps {
    uri: string,
    index: number,
    onRemove: () => void,
}
function ImageThumbnail(props: ImageThumbnailProps) {
    const { uri, index, onRemove } = props;
    return (
        <div key={index} style={styles.imgPreviewItem}>
            <ImageWithSpinner
                resizeMode={"cover"}
                spinnerSize={15}
                customStyle={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent"
                }}
                uri={uri}
            />
            <div
                style={{
                    ...{ width: 20, height: 20, borderRadius: 10, position: 'absolute', top: -5, right: -5, cursor: 'pointer', zIndex: 2 },
                    ...{ backgroundColor: 'red' }
                }}
                onClick={onRemove}
            >
                <AiOutlineClose size={20} color={'#fff'} />
            </div>

        </div>
    )
}


const styles: { [key: string]: React.CSSProperties } = ({
    lableHeader: {
        fontSize: 13,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        marginBottom: 10,
        marginTop: 10,
        marginRight: 5,
        color: THEME.BLACK
    },
    inputIconView: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        // textAlignVertical: "top",
        minHeight: 45,
        color: THEME.BLACK,
        width: '100%',
        borderRadius: 10,
        paddingLeft: 15,
        fontFamily: FONT.MANROPE_REGULAR,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid',
        fontSize: 12
    },
    validatorError: {
        color: 'red',
        padding: 5,
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR

    },

    //selector styles
    container: {
        display: 'flex', flexDirection: 'column',
        width: '100%',
        position: 'relative',
        boxSizing: 'border-box'
    },
    innercontainer: {
        position: 'relative',
        width: '100%',
        borderRadius: 10,
        borderWidth: 2,
        borderColor: 'rgb(244,244,244)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
    },
    // label: {
    //     position: 'absolute',
    //     top: -8,
    //     left: 20,
    //     backgroundColor: THEME.WHITE,
    //     paddingLeft: 10,
    //     paddingRight: 10,
    // },
    labelTxt: {
        fontSize: 12,
        fontFamily: FONT.MANROPE_REGULAR
    },
    innervalue: {
        display: 'flex',
        flexDirection: 'row',
    },
    thumbnail: {
        width: 30, height: 25, marginTop: 10, marginRight: 10, borderRadius: 10
    },
    triangle: {
        height: 6,
        width: 10,
        backgroundColor: "transparent",
        borderColor: "transparent",

    },

    multiInput: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    selectorText: {
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        color: THEME.BLACK
    },

    selectorPlaceholder: {
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        color: THEME.PLACEHOLDER
    },

    //Image input

    imgInputContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        borderRadius: 10,
        padding: 10,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'dashed',
        boxSizing: 'border-box'
    },
    imgInstructions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgPreviewContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    imgInputPlaceholder: {
        fontSize: 16, color: '#B2B2B2'
    },
    brwTxt: {
        textDecorationLine: 'underline'
    },

    //image preview styles

    imgPreviewItem: {
        position: 'relative',
        display: 'flex',
        width: boxWidth,
        height: boxWidth + 10,
        marginTop: 8,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid',
        borderRadius: 5,
        marginRight: 10
    },

    addMoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: boxWidth - 20,
        height: boxWidth - 20,
        marginTop: 20,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid',
        borderRadius: 5
    },


    //Image & form input styles

    optionImg: {
        width: 20, height: 30, borderWidth: 1, borderColor: 'rgba(130,130,130,0.2)'
    },
    optionImgIcon: {
        width: 20, height: 20
    },

    optionMenuContainer: {
        // position: 'absolute',
        // bottom: -65,
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'flex-end',
        width: '100%',
        paddingTop: 5, paddingBottom: 5,
        backgroundColor: THEME.WHITE,
    },

    optionMenu: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid'
        // borderRadius: 10,
    },

    optionBtn: {
        paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5
    },

});