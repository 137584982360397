import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton, SaveQuestionModal, SelectorList, SurveyDurationList, VideoErrorMessage } from "../components";
import { DEVICE_TYPE, FONT, QUESTION_TYPE, THEME } from "../enums";
import { MySurvey, ShortAnswerSurveyForm } from "../interfaces";
import { MAIN_APP_ROUTES } from "../routes";
import { getDurationByDate, getFileSize, getQuestionInitObject, getSubscriptionCount } from "../services";
import { FormInput, FormSelector, ImageInput, CustomToggle } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS, FILE_SIZE_LIMIT } from "../variables";
import * as fromStore from "../store";
import { SpinnerScreen } from "../utils/spinner.screen";
import { UploadInfoModel } from "../components/upload-info-model";

const wrapperHeight = (currentWindowHeight() / 3) * 2;
const questionBank: MySurvey[] = require("../../assets/others/question-bank.json");

const SINGLE_OPTION = [
    {
        field: 'text',
        label: 'yourQuestion',
        required: true,
        secure: false,
        type: 'none',
        textContentType: 'none',
        placeholder: 'Can you all let me know if you are coming to Tasha’s wedding? So that I can coordinate.',
        multiline: true,
        numberOfLines: 4,
        backgroundColor: THEME.INPUT_BACKGROUND

    },
    {
        field: 'image',
        label: 'uploadImageFiles',
        secure: false,
        type: 'image',
        textContentType: 'none',
        placeholder: 'dragFilesHere',
    },
    // {
    //     field: 'category',
    //     label: 'selectACategory',
    //     secure: false,
    //     type: 'selector',
    //     textContentType: 'none',
    //     placeholder: 'Relationship',

    // },
    {
        field: 'nameTheSurvey',
        label: 'nameTheSurvey',
        secure: false,
        type: 'none',
        textContentType: 'none',
        placeholder: 'Shoes',

    },
    {
        field: 'duration',
        label: 'surveyDuration',
        secure: false,
        type: 'selector',
        textContentType: 'none',
        placeholder: '30 minutes',

    },
    {
        field: 'resultpermission',
        label: 'Make Results Public',
        secure: false,
        type: 'toggle',
        textContentType: 'none',
        placeholder: '',

    },
];

const CATEGORIES = [
    { name: "Relationship", code: "R" },
    { name: "Cloths", code: "C" },
    { name: "Friends", code: "F" },
    { name: "Others", code: "O" },
]

export function ShortAnswerScreen(props: any) {
    const { history, location, match } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { error, loading, draft } = useSelector((state) => fromStore.selectDraftState(state));
    const categories = useSelector((state) => fromStore.selectCategories(state));
    const categoryState = useSelector((state) => fromStore.selectCategoriesState(state));
    const [isVisible, setIsVisible] = useState<any>({
        category: false,
        duration: false,
        mediaInfo: false,
    });
    const [state, setState] = useState<ShortAnswerSurveyForm>({
        id: undefined,
        type: QUESTION_TYPE.SHORT_ANSWER,
        text: '',
        category: null,
        nameTheSurvey: '',
        duration: null,
        image: null,
        imageUrl: null,
        resultpermission: false,
    });
    const [isDraftSave, setIsSaveDraft] = useState<boolean>(false);
    const [isInit, setIsInit] = useState<boolean>(true);
    useEffect(() => {
        removeState();
        dispatch(fromStore.getCategories());
        if (match.params) getDraftInfo(match.params);
        return () => {
            removeState();
            // dispatch(fromStore.saveDrafts());
        }
    }, []);

    useEffect(() => {
        if (draft) initState(draft);
        else {
            setIsInit(false);
        }
        return () => {

        }
    }, [draft]);


    function toggleSwitch(field: string) {
        // console.log(state[field]);
        setState({ ...state, [field]: !state[field] });
        console.log(state);
        // dispatch(fromStore.updateProfileSettings({
        //     id: user?.id as string,
        //     enabledNotifications: field === "enabledNotifications" ? !state[field] : state.enabledNotifications,
        //     newsletterSubscribed: field === "newsletterSubscribed" ? !state[field] : state.newsletterSubscribed
        // }))
    }

    function getDraftInfo(params) {
        if (params && params.id && questionBank.find(doc => doc.id === params.id)) {
            const data = questionBank.find(doc => doc.id === params.id);
            if (data) setState({
                id: undefined,
                type: QUESTION_TYPE.SHORT_ANSWER,
                text: data.text,
                category: data.category,
                nameTheSurvey: data.nameTheSurvey ? data.nameTheSurvey : '',
                duration: getDurationByDate(data.startTime, data.endTime),
                image: null,
                imageUrl: null,
            });
        }
        else if (params && params.id) {
            dispatch(fromStore.removeSurvay());
            dispatch(fromStore.getDraft(params.id));
        }
        else if (location && location.state && location.state.previewQuestion) {
            setState(location.state.previewQuestion);
        }

    }
    function removeState() {
        dispatch(fromStore.removeDraft());
    }

    function initState(draft: MySurvey) {
        const newState = getQuestionInitObject(draft) as ShortAnswerSurveyForm;
        setState(newState);
        setIsInit(false);
    }

    function handleFieldChange(fieldId, value) {
        setState({ ...state, [fieldId]: value });
    }

    function _isFormValid() {
        const { text, category, nameTheSurvey, duration, image } = state;
        if (text == '') return false;
        // if (!category) return false;
        // if (nameTheSurvey == '') return false;
        if (!duration) return false;
        if (image && image.size && (getFileSize(image.size) > FILE_SIZE_LIMIT)) return false;
        return true;
    }

    // function _isDirty(){
    //     if (!match.params && !match.params.data) return true;
    //     const { text, category, nameTheSurvey, duration } = state;
    //     if(text !== match.params.data.text) return false;
    //     if(category !== match.params.data.category) return false;
    //     if(duration !== match.params.data.duration) return false;
    //     // if(text !== match.params.data.text) return true;
    //     return true;
    // }

    // function getSurvayDurationInfo(value: any) {
    //     if (!value) return '';
    //     const name = getSubscriptionCount(value);
    //     if (name) {
    //         return {
    //             name,
    //         }
    //     }
    //     return '';

    // }


    if ((!draft && loading) || isInit) {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <SpinnerScreen />
            </div>
        )
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={styles.container}>
                <div style={styles.header}>
                    <CustomText customStyle={styles.headerTxt}>{t('createShortAnswerQuestion')}</CustomText>
                </div>
                <div style={styles.formContent}>
                    {SINGLE_OPTION.map((doc, index) => (
                        <div key={doc.field} style={styles.inputField}>
                            {doc.type === 'selector' &&
                                <FormSelector
                                    lable={t(doc.label)}
                                    placeholder={doc.placeholder}
                                    selected={state[doc.field]}
                                    translate={doc.field != "category"}
                                    onClick={() => setIsVisible({ [doc.field]: !isVisible[doc.field] })}
                                />
                            }
                            {doc.type === 'toggle' &&
                                <div>
                                    <div style={styles.option}>
                                        <CustomText customStyle={styles.title}>{t(doc.label)}</CustomText>
                                    </div>
                                    <CustomToggle
                                        checked={state[doc.field]}
                                        onClick={() => {
                                            toggleSwitch(doc.field)
                                        }}
                                    />
                                </div>
                            }
                            {
                                (doc.type === 'image') && <div style={styles.inputField}>
                                    <ImageInput
                                        label={t(doc.label)}
                                        placeholder={t(doc.placeholder)}
                                        image={state[doc.field]}
                                        uri={state.imageUrl}
                                        onClick={(item) => {
                                            setState({ ...state, [doc.field]: item })
                                        }}
                                        onRemove={() => {
                                            setState({ ...state, [doc.field]: null, imageUrl: null })
                                        }}
                                        onInfo={() => { setIsVisible({ ...isVisible, mediaInfo: true }) }}
                                    />
                                </div>
                            }
                            {(doc.type === 'image' && state.image && state.image.size && state.image.size) && <VideoErrorMessage bytes={state.image.size} />}
                            {
                                (doc.type !== 'selector' && doc.type !== 'multiselector' && doc.type !== 'image' && doc.type !== 'toggle') && <FormInput
                                    id={doc.field}
                                    onChange={handleFieldChange}
                                    secure={doc.secure}
                                    placeholder={doc.placeholder}
                                    type={doc.type}
                                    value={state[doc.field]}
                                    // pattern={doc.pattern}
                                    label={t(doc.label)}
                                    // keyboardtype={doc?.keyboardtype}
                                    multiline={doc.multiline}
                                    numberOfLines={doc.numberOfLines}
                                    required={doc.required}
                                    textContentType={doc.textContentType}
                                    backgroundColor={doc.backgroundColor}
                                />
                            }

                        </div>
                    ))}


                </div>
                <div style={styles.actions}>
                    <div style={styles.btn}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('preview')}
                            enable={_isFormValid()}
                            padding={12}
                            onClick={() => {
                                history.push(MAIN_APP_ROUTES.PREVIEW_QUESTION, {
                                    type: QUESTION_TYPE.SHORT_ANSWER,
                                    question: state,
                                    id: match.params ? match.params.id : undefined
                                })
                            }}
                        />
                    </div>
                    <div style={styles.btn}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('save')}
                            enable={_isFormValid()}
                            padding={12}
                            onClick={() => {
                                setIsSaveDraft(!isDraftSave)
                            }}
                        />
                    </div>
                    <div style={styles.btn}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('post')}
                            enable={_isFormValid()}
                            padding={12}
                            onClick={() => postMyQuestion()}
                        />
                    </div>
                    <div style={styles.btn}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            // customStyle={{ backgroundColor: THEME.CANCEL }}
                            name={t('cancel')}
                            enable={true}
                            padding={12}
                            backgroundColor={THEME.CANCEL}
                            onClick={() => { history.push(MAIN_APP_ROUTES.CREATE_QUESTION); }}
                        />
                    </div>
                </div>

            </div>
            {isVisible['category'] &&
                <SelectorList
                    list={categories}
                    selected={state['category']}
                    loading={categoryState.loading}
                    type={"surveyCategory"}
                    setItem={(doc) => {
                        setState({ ...state, ['category']: doc });
                        setIsVisible({ ...isVisible, category: false });
                    }}
                    title={t("category")}
                    visible={isVisible['category']}
                    wrapperHeight={wrapperHeight}
                    onClose={() => { setIsVisible({ ...isVisible, category: false }); }}
                    onDissmiss={() => { setIsVisible({ ...isVisible, category: false }); }}
                />}
            {isVisible['duration'] && 
                <SurveyDurationList
                    selected={state['duration']}
                    loading={false}
                    setItem={(doc) => {
                        setState({ ...state, ['duration']: doc });
                        setIsVisible({ ...isVisible, duration: false });
                    }}
                    title={t("duration")}
                    visible={isVisible['duration']}
                    wrapperHeight={wrapperHeight}
                    onClose={() => { setIsVisible({ ...isVisible, duration: false }); }}
                    onDissmiss={() => { setIsVisible({ ...isVisible, duration: false }); }}
                />
            }
            {isDraftSave && <SaveQuestionModal
                uploading={isDraftSave}
                doc={state}
                onClick={() => { }}
                onClose={() => { setIsSaveDraft(!isDraftSave) }}
                onSuccess={onSucces}
            />}
            {isVisible['mediaInfo'] && <UploadInfoModel
                type={"image"}
                visible={isVisible['mediaInfo']}
                onClose={() => { setIsVisible({ ...isVisible, mediaInfo: false })}}
                onDissmiss={() => { setIsVisible({ ...isVisible, mediaInfo: false })}}
            />}
        </div>
    );

    // function saveQuestion() {
    //     dispatch(fromStore.addDraft({
    //         id: match.params && match.params.data ? match.params.data.id : new Date().getTime().toString(),
    //         type: QUESTION_TYPE.SHORT_ANSWER,
    //         question: { ...state, createdAt: new Date().toISOString() }
    //     }));
    //     dispatch(fromStore.saveDrafts());
    //     history.push(MAIN_APP_ROUTES.DRAFTS);
    // }

    function onSucces(response) {
        if (state.id) dispatch(fromStore.updateDraftSuccess(response));
        else dispatch(fromStore.saveDraftSuccess(response));
        setIsSaveDraft(!isDraftSave);
        history.push(MAIN_APP_ROUTES.DRAFTS);
    }
    function postMyQuestion() {
        // if (!state.image) return;
        // uploadQuestionImage(state.image);
        // console.log(SurveysService.getQuestionSendFormat(state));
        history.push(MAIN_APP_ROUTES.POST_QUESTION, { question: state })
    }
}


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        height: '100%',
        overflowY: 'auto'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerTxt: {
        fontSize: 15,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica

    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputField: {
        marginBottom: 5
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: 20
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        // height: 40,
        marginBottom: 15
    },
    selectorMenu: {
        position: 'absolute',
        backgroundColor: THEME.WHITE,
        width: '100%',
        height: '100%',
        paddingTop: 20
    }
})