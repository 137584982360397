import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CommentFormScreen, CommentsSection, MultiChoiceSurvey, PostCommentModal, ScreenHolder, ShortAnswerSurvey, SingleOptionSurvey } from "../components";
import { DEVICE_TYPE, DRAWER_TYPE, QUESTION_TYPE, THEME } from "../enums";
import { AppError, CommentForm, SurveyAnswer, SurveyAnswerForm } from "../interfaces";
import { MAIN_APP_ROUTES } from "../routes";
import { checkErrorResponse, isSurveyActive } from "../services";
import { PreviewImage, RefreshWithError } from "../utils";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { currentWindowWidth, FULL_SCREEN_HEADER_HEIGHT } from "../variables";
import * as fromStore from "../store";

export function InvitedSurveyScreen(props: any) {
    const { history, match } = props;
    const { params } = match;
    const { t, i18n } = useTranslation();
    const [showToast, setShowToast] = useState<boolean>(false);
    const [state, setState] = useState({
        selectedOption: '',
        comment: '',
        video: null,
        mediaLoding: true,
        imgPreview: false,
        imgUri: ''
    });

    const answersState = useSelector((state) => fromStore.selectAnswersState(state));
    const comments = useSelector((state) => fromStore.selectComments(state));
    const commentsState = useSelector((state) => fromStore.selectCommentsState(state));
    const { error, loading, survey } = useSelector((state) => fromStore.selectSurveyState(state));
    const dispatch = useDispatch();

    useEffect(() => {
        if (params) getSurveyInfo(params);
        return () => {
            removeState();
        }
    }, [])

    useEffect(() => {
        if (answersState.selectedAnswer) selectedAnswer(answersState.selectedAnswer);
    }, [answersState.selectedAnswer])

    useEffect(() => {
        if (survey) {
            dispatch(fromStore.getMyAnswer(survey.id));
            dispatch(fromStore.getComments(survey.id));
        }
        return () => {

        }
    }, [survey])

    function getSurveyInfo(params) {
        // if (params && params.id && entities) setTempSurvey(entities[params.id]);

        if (params && params.id) {
            dispatch(fromStore.removeSurvay());
            dispatch(fromStore.removeSelectedAnswer());
            dispatch(fromStore.getInvitedSurvey(params.id));
        }
    }
    function removeState() {
        dispatch(fromStore.removeSurvay());
        dispatch(fromStore.removeSelectedAnswer());
        dispatch(fromStore.removeComments());
        dispatch(fromStore.removeAnswers());
    }

    function selectedAnswer(doc: SurveyAnswer) {
        if (!doc.option) return;
        setState({ ...state, selectedOption: doc.option.id })
    }


    if (!survey && loading) {

        return (
            <ScreenHolder location={location} history={history} >
                <div style={styles.container}>
                    <div style={styles.header}>
                        <CustomText customStyle={styles.shdtxt}>{t('yourHelpMatters')}</CustomText>
                    </div>
                    <div style={styles.content}>
                        <SpinnerScreen />
                    </div>
                </div>
            </ScreenHolder>
        )
    }

    if (error) {
        return (
            <ScreenHolder location={location} history={history} >
                <div style={styles.container}>
                    <div style={styles.header}>
                        <CustomText customStyle={styles.shdtxt}>{t('yourHelpMatters')}</CustomText>
                    </div>
                    <div style={styles.content}>
                        <RefreshWithError text={checkErrorResponse(error)} onRefresh={() => { getSurveyInfo(params) }} />
                    </div>
                </div>
            </ScreenHolder>
        )
    }

    return (
        <ScreenHolder location={location} history={history} >
            <div style={styles.container}>
                <div style={styles.header}>
                    <CustomText customStyle={styles.shdtxt}>{t('yourHelpMatters')}</CustomText>
                </div>
                {survey ? <div style={styles.content}>
                    <ShowSurveyByType />
                    {survey && isSurveyActive(survey.endTime) && <CommentFormScreen
                        state={state}
                        onState={(doc) => setState(doc)}
                        onChange={handleFieldChange}
                        onSubmit={submitComment}
                        onCancel={cancelComment}
                    />}
                    <CommentsSection
                        deleteState={commentsState.delete}
                        onDelete={(doc) => { }}
                        enableDelete={true}
                        onVideoClick={(url) => { history.push(MAIN_APP_ROUTES.VIDEO_PLAYER, { uri: url }) }}
                    />
                </div> :
                    <div style={styles.content}>
                        <SpinnerScreen />
                    </div>
                }
                {survey && showToast && <PostCommentModal
                    doc={{
                        text: state.comment,
                        video: state.video,
                        userId: survey.user?.id,
                        questionId: survey.id
                    }}
                    visible={showToast}
                    onSuccess={onCommentSuccess}
                    onClose={() => { dispatch(fromStore.clearCommentsError()); setShowToast(false); }}
                />}
                {state.imgPreview && <PreviewImage
                    uri={state.imgUri}
                    visible={state.imgPreview}
                    onClose={() => { setState({ ...state, imgPreview: false }) }}
                    onDissmiss={() => { setState({ ...state, imgPreview: false }) }}
                />}
            </div>
        </ScreenHolder>
    );



    function onImage(uri: string) {
        setState({ ...state, imgPreview: true, imgUri: uri })
    }

    function onRefresh() {
        getSurveyInfo(params);
        dispatch(fromStore.getComments(params.id));
    }

    function submitComment() {
        // dispatch(fromStore.postComment(doc));
        setShowToast(true);
    }

    function cancelComment() {
        setState({ ...state, comment: '' });
    }

    function submitAnswer(doc: SurveyAnswerForm) {
        if (answersState.selectedAnswer) {
            dispatch(fromStore.updateAnswer({ ...doc, id: answersState.selectedAnswer.id }))
        } else {
            dispatch(fromStore.postAnswer(doc));
        }
    }

    function onError() {
        // console.log('error');
        setShowToast(true);
    }

    function onCommentSuccess() {
        // console.log('success');
        setState({ ...state, comment: '', video: null });
        dispatch(fromStore.toastMessage(t('commentPosted')));
        setShowToast(false);
    }



    function ShowSurveyByType() {

        if (survey && survey.type === QUESTION_TYPE.SINGLE_OPTION) {
            return (
                <SingleOptionSurvey
                    survey={survey}
                    totalComments={comments.length}
                    state={state}
                    onImage={onImage}
                    onStateChange={(doc) => setState(doc)}
                    onSubmitAnswer={submitAnswer}
                />
            )
        }

        if (survey && survey.type === QUESTION_TYPE.MULTIPLE_CHOICE) {
            return (
                <MultiChoiceSurvey
                    survey={survey}
                    totalComments={comments.length}
                    state={state}
                    onImage={onImage}
                    onStateChange={(doc) => setState(doc)}
                    onSubmitAnswer={submitAnswer}
                />
            )
        }

        if (survey && survey.type === QUESTION_TYPE.SHORT_ANSWER) {
            return (
                <ShortAnswerSurvey
                    survey={survey}
                    totalComments={comments.length}
                    onImage={onImage}
                />
            )
        }


        return null;

    }

    function handleFieldChange(fieldId, value) {
        setState({ ...state, [fieldId]: value });
    }
}



const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '92%',
        overflowY: 'auto',
        padding: 20,
    },
})