import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton, ScreenHolder, SelectorList } from "../components";
import { THEME, FONT } from "../enums";
import { LOGIN_SIGNUP_ROUTES, MAIN_APP_ROUTES } from "../routes";
import { CustomCheckBox, FormInput, FormSelector } from "../utils";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { currentWindowHeight, EMAIL_PATTERN, GENDERS } from "../variables";
import * as fromStore from "../store";
import * as AuthService from "../services/auth.service";
import { extractError } from "../services";
import { EmailSignupCredentials } from "../interfaces";
import { COUNTRY_CODES } from "../utils/country-code";
import { exit } from "process";

const wrapperHeight = (currentWindowHeight() / 3) * 2;

const REGISTER_FORM = [
  {
    field: "name",
    label: "",
    secure: false,
    type: "username",
    placeholder: "NAME",
    pattern: "",
  },
  {
    field: "email",
    label: "",
    secure: false,
    type: "emailAddress",
    placeholder: "EMAIL",
    pattern: EMAIL_PATTERN,
  },
  {
    field: "country",
    label: "",
    secure: false,
    type: "selector",
    placeholder: "Country Code",
    pattern: "",
  },
  {
    field: "phone",
    label: "",
    secure: false,
    type: "telephoneNumber",
    keyboardtype: "phone-pad",
    placeholder: "PHONE",
    pattern: "",
  },
  {
    field: "gender",
    label: "",
    secure: false,
    type: "selector",
    placeholder: "GENDER",
    pattern: "",
  },
  {
    field: "password",
    label: "",
    secure: true,
    type: "password",
    placeholder: "PASSWORD",
    pattern: /^\w+([\.-]?\w+)/,
  },
  {
    field: "confirmPassword",
    label: "",
    secure: true,
    type: "password",
    placeholder: "CONFIRM PASSWORD",
    pattern: /^\w+([\.-]?\w+)/,
  },
];

interface State {
  name: string;
  email: string;
  phone: any;
  gender: any;
  country: any;
  password: string;
  confirmPassword: string;
  newsLetter: boolean;
}

export function RegisterScreen(props: any) {
  const { history, location } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { loading, error, country } = useSelector((state) =>
    fromStore.selectAuthState(state)
  );
  const [state, setState] = useState<State>({
    name: "",
    email: "",
    phone: "",
    gender: null,
    country: "",
    password: "",
    confirmPassword: "",
    newsLetter: false,
  });
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isCountryVisible, setIsCountryVisible] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (country) setState({ ...state, country: country });
  }, [country]);

  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  function handleFieldChange(fieldId, value) {
    setState({ ...state, [fieldId]: value });
  }

  function _isFormValid() {
    const { name, email, country, phone, gender, password, confirmPassword } =
      state;
    const pattern = EMAIL_PATTERN;
    if (name === "") return false;
    if (email === "") return false;
    // if (phone === '') return false;
    if (phone && isNaN(phone)) return false;
    //if (country === "") return false;
    if (phone && (phone.length > 10 || phone.length < 10)) return false;
    if (gender === null) return false;
    if (pattern.test(email) === false) return false;
    if (password === "") return false;
    if (password && password.length < 6) return false;
    if (password && password.length >= 15) return false;
    if (confirmPassword === "") return false;
    if (confirmPassword && confirmPassword.length < 6) return false;
    if (confirmPassword && confirmPassword.length >= 15) return false;
    if (confirmPassword && confirmPassword != password) return false;
    return true;
  }

  if (loading) return <SpinnerScreen />;

  const getInputClass = (type: string) => {
    if(type === 'phone') {
        return styles.phone
    } else if (type === 'country') {
        return styles.country
    } else {
        return styles.inputField

    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <CustomText customStyle={styles.headerText}>{t("register")}</CustomText>
      </div>
      <div style={styles.registerForm}>
        {REGISTER_FORM.map((doc, index) => (
          <div key={doc.field} style={ getInputClass(doc.field) }>
            {doc.type !== "selector" ? (
              <FormInput
                id={doc.field}
                onChange={handleFieldChange}
                secure={doc.secure}
                placeholder={doc.placeholder}
                type={doc.type}
                value={state[doc.field]}
                pattern={doc.pattern}
                label={doc.label}
                password={state.password}
                keyboardtype={doc?.keyboardtype}
              />
            ) : (
              <FormSelector
                placeholder={doc.placeholder}
                selected={state[doc.field]}
                onClick={() => {
                  if (doc.field === "gender") {
                    setIsVisible(true);
                  } else {
                    setIsCountryVisible(true);
                  }
                }}
              />
            )}
          </div>
        ))}
      </div>
      <div style={styles.newsLetter}>
        <CustomCheckBox
          title={t("subscribeForNewsLetter")}
          checked={state["newsLetter"]}
          checkedColor={"red"}
          containerStyle={styles.checkbox}
          onClick={() => handleFieldChange("newsLetter", !state["newsLetter"])}
        />
      </div>
      <div style={styles.action}>
        <CustomButton
          name={t("register")}
          enable={_isFormValid()}
          onClick={() => {
            registerNewUser();
          }}
        />
      </div>
      <div style={styles.loginContainer}>
        <CustomText customStyle={styles.loginText}>{`${t(
          "alreadyHaveAnAccount"
        )} `}</CustomText>
        <div
          style={{
            ...styles.login,
          }}
          onClick={() => {
            history.push({
              pathname: LOGIN_SIGNUP_ROUTES.LOGIN,
              state: location.state,
            });
          }}
        >
          <CustomText
            className={"active-text"}
            customStyle={{ ...styles.loginText, ...{ fontWeight: "bold" } }}
          >
            {t("loginT")}
          </CustomText>
        </div>
      </div>
      {isVisible && (
        <SelectorList
          list={GENDERS}
          selected={state["gender"]}
          loading={false}
          type={"basic"}
          setItem={(doc) => {
            setState({ ...state, ["gender"]: doc });
            setIsVisible(false);
          }}
          title={t("gender")}
          visible={isVisible}
          wrapperHeight={wrapperHeight}
          onClose={() => {
            setIsVisible(false);
          }}
          onDissmiss={() => {
            setIsVisible(false);
          }}
        />
      )}

      {isCountryVisible && (
        <SelectorList
          list={COUNTRY_CODES}
          selected={state["country"]}
          loading={false}
          type={"basic"}
          setItem={(doc) => {
            // console.log(doc);
            setState({ ...state, ["country"]: doc });
            setIsCountryVisible(false);
          }}
          title={t("Country")}
          visible={isCountryVisible}
          wrapperHeight={wrapperHeight}
          onClose={() => {
            setIsCountryVisible(false);
          }}
          onDissmiss={() => {
            setIsCountryVisible(false);
          }}
        />
      )}
    </div>
  );

  async function registerNewUser() {
    try {
      const { name, email, password, gender, phone, country, newsLetter } =
        state;
      const fullName = name.split(" ");
      
      // console.log(country.dial_code);

      const credentials: EmailSignupCredentials = {
        firstName: fullName[0],
        lastName: fullName[1],
        email: email,
        login: email,
        gender: gender.code,
        phone: country.dial_code+phone,
        dateOfBirth: null,        
        newsletterSubscribed: newsLetter,
        password: password,
      };
      // console.log(credentials,'uuuu');

      // const { firstName, email } = credentials;
      // dispatch(fromStore.getUser({username:email}));
      // dispatch(fromStore.emailLogin({ username: email, password: password, rememberMe: true }))

      // return false;
      dispatch(fromStore.emailSignup(credentials));
      const response = await AuthService.emailSignupRequest(credentials);
      // console.log(response);
      dispatch(fromStore.emailSignupSuccess());
      history.push({
        pathname: LOGIN_SIGNUP_ROUTES.THANKS,
        state: location.state,
      });
    } catch (error: any) {
      dispatch(
        fromStore.emailSignupError(
          extractError(error, LOGIN_SIGNUP_ROUTES.REGISTER)
        )
      );
    }
  }
}

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: THEME.WHITE,
    position: "relative",
  },
country:{
    marginBottom:'5px',
    display:'inline-block',
    width:'calc (30%,20px)',
    height:'20px',
},
phone:{
    marginBottom:'5px',
    display:'inline-block',
    width:'88%',
    marginLeft:'20px',
},



  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10,
  },
  headerText: {
    fontSize: 17,
    color: THEME.BLACK,
  },
  registerForm: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
  },
  inputField: {
    marginBottom: 5,
  },
  newsLetter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 15,
  },
  action: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  loginContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 10,
    marginBottom: 20,
  },
  login: {
    display: "flex",
    flexDirection: "row",
  },
  loginText: {
    fontSize: 11,
    fontFamily: FONT.MANROPE_REGULAR,
    marginRight: 5,
  },
  checkbox: {
    backgroundColor: THEME.WHITE,
    borderWidth: 0,
  },
};
